exports.getDivision = (grades, totalGrades) => {
	// console.log("Grading = ", grades);
	let division = "X";
	// grades.map((gr) => {});
	if (grades.length >= 4) {
		if (totalGrades >= 4 && totalGrades <= 12) division = "I";
		else if (totalGrades >= 13 && totalGrades <= 24) division = "II";
		else if (totalGrades >= 25 && totalGrades <= 32) division = "III";
		else if (totalGrades >= 33 && totalGrades <= 35) division = "IV";
		else division = "U";
	}
	return division;
};

exports.getDescriptor = (flt) => {
	let identifier = parseInt(this.getIdentifier(flt));
	// console.log("Identifier =", identifier);

	let descriptor = "Basic";
	if (identifier == 1) descriptor = "Basic";
	else if (identifier == 2) descriptor = "Moderate";
	else descriptor = "Outstanding";
	return descriptor;
};

exports.getIdentifier = (_ave) => {
	const ave = parseFloat(_ave);
	let identifier = 0;
	if (ave <= 1.4) {
		identifier = 1;
		// console.log("Identifier =", identifier);
	} else if (ave >= 1.41 && ave <= 2.4) identifier = 2;
	else identifier = 3;
	return identifier;
};
