import React, { useContext } from "react";
import GradingScale from "../GradingScale";
import MainFooter from "../ReportFooters/MainFooter";
import MainHeader from "../ReportHeaders/MainHeader";
import PrintPreview from "../PrintPreview";
import { GlobalContext } from "../../../../GlobalContext";
import { useParams } from "react-router-dom";

function CummulativeOldFashion(props) {
	const { sheetData } = props;
	const { schoolData, api_route } = useContext(GlobalContext);
	const { page, perpage } = useParams();
	console.log("sheetData");
	console.log(sheetData);
	const cls = sheetData.class;
	const subjects = sheetData.subjects;
	const term = sheetData.term;
	const students = sheetData.students;
	const assessments = sheetData.assessments;
	const grading = sheetData.grading;
	const initials = sheetData.initials;
	// const RowComponent = (props) => {
	// 	const { subject, score, grd } = props;
	// 	let teacher_initials = "";

	// 	if (!score) return <></>;

	// 	initials.map((tr) => {
	// 		if (tr.subjectId == subject.id) teacher_initials = tr.initials;
	// 	});
	// 	let showGrade = score.score == null ? false : true;

	// 	return (
	// 		<tr className="text-xs text-center">
	// 			<th className="text-left">
	// 				{subject.subject.length > 10
	// 					? subject.alias
	// 					: subject.subject}
	// 			</th>

	// 			<th>{score.score != null ? score.score : "-"}</th>
	// 			<th>2.3</th>
	// 			<th>2.3</th>
	// 			<th>2.3</th>
	// 			<th>2.3</th>
	// 			<th>2.3</th>
	// 			<th>2.3</th>
	// 			<th>2.3</th>
	// 			<th>2.3</th>
	// 			<th>9.4</th>
	// 			<th>2.3</th>
	// 			<th className="report-darker-cell text-green-900">2.3</th>
	// 			<th className="report-darker-cell text-green-900">9.4</th>
	// 			<th style={{ whiteSpace: "nowrap" }} className="text-left">
	// 				Accomplished{" "}
	// 				<small className="text-red-500">({teacher_initials})</small>
	// 			</th>
	// 		</tr>
	// 	);
	// };
	const getSubject = (subjectId) => {
		let subject = {};
		subjects.map((sb) => {
			if (sb.id == subjectId) subject = sb;
		});
		return subject;
	};
	const getScore = (subjectId, array) => {
		return new Promise((resolve, reject) => {
			let f = null;
			for (let x = 0; x < array.length; x++) {
				if (subjectId == array[x].subjectId) {
					console.log("Found this ", array[x]);
					f = array[x];
				}
			}
			if (f) resolve(f);
			else reject();
		});
	};
	return (
		<PrintPreview>
			{students.map((student, index) => {
				if (index + 1 >= page * perpage - 10 && index + 1 <= page * perpage) {
					let total_score = 0;
					let total_grade = 0;
					let all_aves = [];
					let all_20s = [];
					let all_80s = [];
					let all_100s = [];
					return (
						<div className="mx-auto report-a4-height rounded-[4px] p-6 flex flex-col">
							<MainHeader photo={student.picture} schoolData={props.schoolData} />
							<div className="flex-grow flex items-center justify-center relative">
								<div className="absolute h-2/3 w-2/3 flex items-center justify-center">
									<img draggable={false} src={`${api_route}/images/${!props.schoolData.logo ? "icon-100.png" : props.schoolData.logo}`} className="object-cover opacity-[0.2] " alt="school badge" />
								</div>
								<div className="absolute inset-0 h-full w-full overflow-hidden flex flex-col pb-2">
									<div className="flex-grow">
										<div className="flex items-center flex-wrap">
											<p className="bg-slate-900 text-white py-[6px] mt-2 text-center w-full text-[13px] font-medium">ACADEMIC PERFORMANCE REPORT CARD</p>
											<table cellPadding={4} className="w-full intro-table text-[11px]">
												<tr>
													<th className="w-[150px]">Student Name:</th>
													<th>{student.fullName}</th>
													<th className="w-[150px]">Academic Year:</th>
													<th>{term.year}</th>
												</tr>
												<tr>
													<th>Class:</th>
													<th>
														{cls.class} ({cls.alias}){" - "}
														{student.stream && <span className="text-red-600">{student.stream}</span>}
													</th>
													<th>Academic Term:</th>
													<th>TERM {term.term == 1 ? "ONE" : term.term == 2 ? "TWO" : "THREE"}</th>
												</tr>
											</table>
											<table cellPadding={5} className="bare-table noborder isolated-borders w-full text-[11px]">
												<tr className="bg-[#4b4b4b] text-white">
													<th
														style={{
															borderTop: "none",
															borderBottom: "none",
															borderLeft: "none",
														}}
														className="w-[150px] text-left"
													>
														SUBJECT
													</th>
													{student.assessments.map((ass, i) => (
														<th className="w-[35px]">{ass.title}</th>
													))}
													<th>AVE</th>
													<th>x / 20</th>
													<th>x / 80</th>
													<th>x / 100</th>
													{student.stream && <th className="w-[90px]">STREAM RANK</th>}
													<th className="w-[80px]">CLASS RANK</th>

													<th className="text-left" style={{}}>
														Remark
													</th>
												</tr>
												{subjects.map((sb, i) => {
													let ass_scores = [];
													let rank1 = "";
													let rank2 = "";
													return (
														<tr key={"sbjr-" + index + student.studentId} className="bg-transparent">
															<th className="text-left">{sb.subject.length > 10 ? sb.alias : sb.subject}</th>
															{/* {ass.scores.map(
															(sc, x) => (
																<th>
																	{sc.score}
																</th>
															)
														)} */}
															{student.assessments.map((ass, i) => {
																for (let x = 0; x < ass.scores.length; x++) {
																	if (sb.id == ass.scores[x].subjectId) {
																		console.log("Found this ", ass.scores[x]);
																		let scr = ass.scores[x];
																		ass_scores.push(scr.score);
																		rank1 = scr.subject_rank_stream + "/" + scr.subject_stream_total;
																		rank2 = scr.subject_rank_class + "/" + scr.subject_class_total;
																		return <th className={`${scr.score > 3 || scr.score < 1 ? "bg-red-500 text-white" : ""}`}>{scr.score}</th>;
																	} else if (x == ass.scores.length - 1) return <th></th>;
																}
															})}
															{(() => {
																let ave = ass_scores.reduce((a, b) => a + b, 0);
																all_aves.push(ave / assessments.length);
																let _20 = Math.round((ave / (3 * assessments.length)) * 20);
																let _80 = Math.round((ave / (3 * assessments.length)) * 80);
																let _100 = Math.round((ave / (3 * assessments.length)) * 100);

																all_20s.push(_20);
																all_80s.push(_80);
																all_100s.push(_100);

																return (
																	<>
																		<th className="text-red-600 font-black">{(ave / 3).toFixed(1)}</th>
																		<th>{_20}</th>
																		<th>{_80}</th>
																		<th>{_100}</th>
																		{student.stream && <th>{rank1}</th>}
																		<th>{rank2}</th>
																	</>
																);
															})()}

															<th
																style={{
																	whiteSpace: "nowrap",
																}}
																className="text-left"
															>
																Accomplished{" "}
																{initials.map((tr) => {
																	if (tr.subjectId == sb.id) return <small className="text-red-500">({tr.initials})</small>;
																})}
															</th>
														</tr>
													);
												})}
												<tr>
													<th colSpan={assessments.length + 1} className="bg-transparent hover:bg-white">
														AVERAGE
													</th>
													<th className="text-[#26704c] font-black">{(all_aves.reduce((a, b) => a + b, 0) / subjects.length).toFixed(1)}</th>
													<th className="text-[#26704c] font-black">{Math.round(all_20s.reduce((a, b) => a + b, 0) / subjects.length)}</th>
													<th>{Math.round(all_80s.reduce((a, b) => a + b, 0) / subjects.length)}</th>
													<th>{Math.round(all_100s.reduce((a, b) => a + b, 0) / subjects.length)}</th>
													{student.stream && <th className="text-red-600 font-black">{student.streamRank + "/" + student.streamTotal}</th>}
													<th className="text-red-600 font-black">{student.classRank + "/" + student.classTotal}</th>
												</tr>
											</table>
											<table cellPadding={5} className="bare-table noborder isolated-borders w-full text-[12px] mt-3 text-left">
												<tr>
													<th
														style={{
															borderColor: "#fff",
															borderWidth: 2,
														}}
														className="bg-[#eee]"
													>
														0
													</th>
													<th
														style={{
															borderColor: "transparent",
														}}
														className="bg-transparent"
													>
														No learning outcomes achieved
													</th>
												</tr>
												<tr>
													<th
														style={{
															borderColor: "#fff",
															borderWidth: 2,
														}}
														className="bg-[#eee]"
													>
														1
													</th>
													<th
														style={{
															borderColor: "#fff",
															borderWidth: 2,
														}}
													>
														Some LOs achieved but not sufficient for overall achievement
													</th>
												</tr>
												<tr>
													<th
														style={{
															borderColor: "#fff",
															borderWidth: 2,
														}}
														className="bg-[#eee]"
													>
														2
													</th>
													<th
														style={{
															borderColor: "#fff",
															borderWidth: 2,
														}}
													>
														Most LOs achieved, enough for overall learning achievement (Moderate)
													</th>
												</tr>
												<tr>
													<th
														style={{
															borderColor: "#fff",
															borderWidth: 2,
														}}
														className="bg-[#eee]"
													>
														3
													</th>
													<th
														style={{
															borderColor: "#fff",
															borderWidth: 2,
														}}
													>
														All LOs achieved with ease (Accomplished)
													</th>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</div>
							<MainFooter student={student} cls={cls} />
						</div>
					);
				}
			})}
		</PrintPreview>
	);
}

export default CummulativeOldFashion;
