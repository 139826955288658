import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowRightIcon, CheckIcon, PencilIcon } from "@heroicons/react/outline";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { failedAlert, loader } from "../../../components/vanilla/Dialogues";
import { selectClasses } from "../../../redux/slice";
import "../css/marksheets.css";
import SubjectPapers from "./components/SubjectPapers";
import { GlobalContext } from "../../../GlobalContext";

function Subjects() {
	const token = localStorage.getItem("token");
	const { classes, myAxios } = useContext(GlobalContext);
	// const [classes, setClasses] = useState([]);
	const initialSubject = {
		id: 0,
		title: "",
		alias: "",
		classId: 0,
	};
	const [subject, setSubject] = useState(initialSubject);
	const [classSubjects, setClassSubjects] = useState(null);
	const [selectedClass, setSelectedClass] = useState(0);

	const loadSubjects = () => {
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/marksheets/subjects/findAll", {
				...subject,
				classId: selectedClass,
			})
			.then((response) => {
				let data = response.data;
				// console.log(data);
				setClassSubjects(data);
				loader();
				// setSubjectData(obj);
				data.map((obj) => {
					if (subjectData?.id == obj.id) {
						setSubjectData(obj);
					}
				});
				// subjectData.id
			})
			.catch((error) => {
				failedAlert("A problem occured.");
				// console.log("error");
				loader();
			});
	};

	const changeSubject = (col, val, id) => {
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		console.log(col, val, id);

		myAxios
			.post("/marksheets/subjects/changeSubject", {
				col,
				val,
				id,
			})
			.then((response) => {
				loadSubjects();
				let data = response.data;
				// console.log(data);
				// setClassSubjects(data);
				loader();
				// setSubjectData(obj);
				// data.map((obj) => {
				// 	if (subjectData?.id == obj.id) {
				// 		setSubjectData(obj);
				// 	}
				// });
				// subjectData.id
			})
			.catch((error) => {
				failedAlert("A problem occured.");
				// console.log("error");
				loader();
			});
	};

	useEffect(() => {
		console.log("Here is one", selectedClass);
		if (!selectedClass) return;
		setSubject((prev) => ({ ...prev, classId: selectedClass }));

		if (classSubjects == null) loadSubjects();
		setSubject(initialSubject);
	}, [selectedClass, classSubjects]);
	// useEffect(() => {});

	const saveSubject = (event) => {
		event.preventDefault();
		console.log(subject);
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/marksheets/subjects/register", {
				...subject,
				classId: selectedClass,
			})
			.then((response) => {
				let data = response.data;
				loader();
				if (data.error) failedAlert(data.error);
				else {
					loadSubjects();
					setSubject((prev) => ({
						...prev,
						title: "",
						alias: "",
						id: 0,
					}));
				}
				// else if(data.errors && data.errors)
				console.log("response -- ", data);
			})
			.catch((error) => {
				loader();
				failedAlert(`Request failed. Please try again. ${error}`);
			});
	};
	const formHandler = (event) => {
		setSubject((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}));
	};
	const [showWindow, setShowWindow] = useState(false);
	const [subjectData, setSubjectData] = useState(null);
	return (
		<div className="h-full pt-10">
			<SubjectPapers showWindow={showWindow} setTPopup={setShowWindow} subjectData={subjectData} loadSubjects={loadSubjects} />
			<div className="max-w-[750px] mx-auto p-5">
				<p className="text-xl flex items-center font-bold">SUBJECTS BY CLASS</p>
				<p className="text-[#999] font-semibold pb-2">select class below</p>
				<form action="" method="post" onSubmit={saveSubject}>
					<input name="id" type="hidden" value={subject.id} />
					<div className="flex items-center flex-wrap gap-3">
						{classes.map((obj, index) => (
							<label className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative`} key={"class-" + obj.id}>
								<input
									type="radio"
									name="classId"
									value={obj.id}
									{...(selectedClass === obj.is && {
										checked: true,
									})}
									onChange={(event) => {
										setSelectedClass(event.target.value);
										setClassSubjects(null);
									}}
								/>
								<p className="mr-auto">{obj.alias}</p>
								<span></span>
								<div className="absolute inset-0"></div>
							</label>
						))}
					</div>
					<div className="flex items-center w-full mb-2 mt-2 max-w-[400px]">
						<input type="text" placeholder="e.g ECONOMICS" className="input-cls" name="title" value={subject.title} onChange={formHandler} required />
						<input type="text" placeholder="e.g ECON" className="input-cls ml-2" name="alias" value={subject.alias} onChange={formHandler} required />
						<button type="submit">
							<ArrowRightIcon className="w-7 p-2 rounded-full bg-blue-800 text-white ml-2" />
						</button>
					</div>
				</form>
				<table className="list-table w-[450px]" cellPadding="3" cellSpacing="0">
					<tbody>
						<tr className="thead text-left">
							<th>SUBJECT</th>
							<th>ALIAS</th>
							<th>CLASS</th>
							<td className="w-[20px]"></td>
							<td className="w-[20px]">UNITS</td>
							<td className="w-[20px]">SUB</td>
							<td className="w-[20px]">GP</td>
						</tr>
						{classSubjects && classSubjects.length > 0 ? (
							classSubjects.map((obj) => (
								<tr key={"subject-" + obj.id}>
									<td>{obj.subject}</td>
									<td>{obj.alias}</td>
									<td>{obj.class.alias}</td>
									<td className="relative">
										<PencilIcon className="w-5 bg-green-100 rounded-full p-1 text-green-800 border-[1px] border-green-800" />
										<div
											onClick={() => {
												setSubject((prev) => ({
													...prev,
													id: obj.id,
													title: obj.subject,
													alias: obj.alias,
													classId: obj.class.id,
												}));
											}}
											className="bg-transparent absolute w-full h-full cursor-pointer top-0 left-0"
										></div>
									</td>
									<td className="relative">
										<div className="flx items-center justify-center">
											<FontAwesomeIcon icon={faCopy} className="w-5 rounded-full text-blue-700 mx-auto" />
											<span className="text-red-500 text-right rounded-lg ml-1">{obj.subject_units?.length}</span>
										</div>
										<div
											onClick={() => {
												setShowWindow(true);
												console.log(obj);
												setSubjectData(obj);
											}}
											className="bg-transparent absolute w-full h-full cursor-pointer top-0 left-0"
										></div>
									</td>
									<td className="text-center">
										<label className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative mx-auto max-w-[100px]`} key={"isSUB-" + obj.id}>
											<input
												type="checkbox"
												name="isSUB"
												value={obj.id}
												{...(obj.sub == "true" && {
													checked: true,
												})}
												onChange={(event) => {
													let ck = event.target;
													let val = parseInt(event.target.value);
													console.log(ck.checked);
													changeSubject("sub", ck.checked, obj.id);
												}}
											/>
											<p className="mr-auto flex-shrink-0"></p>
											<span className="flex-shrink-0">
												<CheckIcon className="w-full text-white" />
											</span>
											<div className="absolute inset-0"></div>
										</label>
									</td>
									<td className="text-center">
										<label className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative mx-auto max-w-[100px]`} key={"isGP-" + obj.id}>
											<input
												type="checkbox"
												name="isGP"
												{...(obj.isGP == "true" && {
													checked: true,
												})}
												onChange={(event) => {
													// setTotalStudents(0);
													let ck = event.target;
													let val = parseInt(event.target.value);
													console.log(ck.checked);
													changeSubject("gp", ck.checked, obj.id);
												}}
											/>
											<p className="mr-auto flex-shrink-0"></p>
											<span className="flex-shrink-0">
												<CheckIcon className="w-full text-white" />
											</span>
											<div className="absolute inset-0"></div>
										</label>
									</td>
								</tr>
							))
						) : (
							<></>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default Subjects;
