export async function resizeImage(file, width) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		const reader = new FileReader();

		reader.onload = function (e) {
			img.src = e.target.result;
		};

		img.onload = function () {
			const aspectRatio = img.height / img.width;
			const canvas = document.createElement("canvas");
			canvas.width = width;
			canvas.height = width * aspectRatio;

			const ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

			canvas.toBlob(
				(blob) => {
					if (blob) {
						resolve(blob);
					} else {
						reject(new Error("Canvas conversion to Blob failed."));
					}
				},
				"image/jpeg", // Preserve original file type
				0.8 // Quality (optional, only applies to lossy formats like JPEG)
			);
		};

		img.onerror = function () {
			reject(new Error("Failed to load the image."));
		};

		reader.readAsDataURL(file);
	});
}

export function removeEmptyRecords() {
	setTimeout(() => {
		let tables = document.querySelectorAll("table.report-table");
		tables = [...tables, ...document.querySelectorAll("table.report-table-black")];

		// console.log("tables", tables);

		if (tables.length > 0) {
			tables.forEach((tb) => {
				const initials = tb.querySelectorAll("tr .initials");
				initials.forEach((i) => {
					if (i.textContent.trim() == "") {
						// console.log(i);
						i.parentNode.style.display = "none";
					}
				});
			});
		}

		setTimeout(async () => {
			// exams_attempted
			let tables = document.querySelectorAll("table.report-table-black");
			let total_subjects = 0;
			tables.forEach((tb) => {
				const rows = tb.querySelectorAll("tbody tr.sb-row");
				rows.forEach((row) => {
					if (row.style.display != "none") total_subjects++;
				});
				let exams_attempted = 'Attempted <span style="color: #f00">1</span> exam';
				if (total_subjects > 0) exams_attempted = `Attempted <span style="color: #f00">${total_subjects}</span> exams`;
				tb.parentNode.parentNode.querySelector(".exams_attempted").innerHTML = exams_attempted;

				const overall_identifier = parseFloat(tb.querySelector(".overall_identifier").textContent);
				if (overall_identifier >= 0.9 && total_subjects >= 7) {
					tb.parentNode.parentNode.querySelector(".promotion_status.promoted").classList.remove("hidden");
					tb.parentNode.parentNode.querySelector(".promotion_status.promoted").innerHTML = "PROMOTED TO THE NEXT CLASS";
				} else {
					tb.parentNode.parentNode.querySelector(".promotion_status.repeat").classList.remove("hidden");
					tb.parentNode.parentNode.querySelector(".promotion_status.repeat").innerHTML = "PROMOTED ON PROBATION";
				}

				total_subjects = 0;
			});
		}, 500);
	}, 200);
}

export function classTeachersComment(identifier = 0, alevel = false) {
	let comments = [
		{
			min: 2.7,
			max: 3,
			comments: ["Wonderful performance and keep it up", "Excellent performance please, keep it up", "Well done please and keep it up", "Good performance and do not relax"],
		},
		{
			min: 2.4,
			max: 2.69,
			comments: ["Well done but do not relax", "Good grades but do not relax", "Fair performance but aim higher"],
		},
		{
			min: 2.1,
			max: 2.39,
			comments: ["Fair grades but you can perform better", "Fair grades but try to balance your subjects", "Fair performance but can do better"],
		},
		{
			min: 1.8,
			max: 2.09,
			comments: ["Academically promising student", "Fair grades but try to balance your subjects", "Fair grades but can perform better"],
		},
		{
			min: 1.5,
			max: 1.79,
			comments: ["There is room for improvement", "Always read more for better grades", "Always read more for better grades"],
		},
		{
			min: 1.2,
			max: 1.49,
			comments: ["Have extra revision for better grades", "Improve in subjects with basic grades", "Read hard in subjects with basic grades"],
		},
		{
			min: 0.9,
			max: 1.19,
			comments: ["Have extra revision for better grades", "Improve in subjects with basic grades", "Low grades but can perform better"],
		},
		{
			min: 0,
			max: 0.89,
			comments: ["Improve in subjects with basic grades"],
		},
	];
	if (alevel == true) {
		comments = [
			{
				min: 15,
				max: 20,
				comments: ["Very good grades but keep reading and do not relax.", "Good performance and do not relax", "Well done but do not relax", "Very good grades and please keep it up", "Well done please and keep it up"],
			},
			{
				min: 10,
				max: 14,
				comments: ["Good performance but do not relax", "Well done but do not relax", "Fair grades but please aim higher", "Good grades but do not relax"],
			},
			{
				min: 7,
				max: 9,
				comments: ["Good grades but please, aim higher", "Fair performance but can do better", "Good grades but please, aim higher"],
			},
			{
				min: 0,
				max: 6,
				comments: ["Low grades but can perform better", "Read hard in subjects with basic grades", "Have extra revision for better grades"],
			},
		];
	}
	for (let x = 0; x < comments.length; x++) {
		if (identifier >= comments[x].min && identifier <= comments[x].max) {
			// console.log("Identifier ", identifier);

			const randomComment = comments[x].comments[Math.floor(Math.random() * comments[x].comments.length)];
			return randomComment;
			break;
		}
	}
}

export function headTeachersComment(identifier = 0, alevel = false) {
	let comments = [
		{
			min: 2.7,
			max: 3,
			comments: ["Very good grades but keep reading and do not relax.", "Good performance and do not relax", "Well done but do not relax", "Very good grades and please keep it up", "Well done please and keep it up"],
		},
		{
			min: 2.4,
			max: 2.69,
			comments: ["Good performance but do not relax", "Well done but do not relax", "Fair grades but please aim higher", "Good grades but do not relax"],
		},
		{
			min: 2.1,
			max: 2.39,
			comments: ["Good grades but please, aim higher", "Fair performance but can do better", "Good grades but please, aim higher"],
		},
		{
			min: 1.8,
			max: 2.09,
			comments: ["Fair performance but aim higher", "Fair grades but try to balance your subjects", "Academically promising student", "Please try to balance your subjects"],
		},
		{
			min: 1.5,
			max: 1.79,
			comments: ["Try to improve in weak subjects", "Please try to balance your subjects", "There is room for improvement"],
		},
		{
			min: 1.2,
			max: 1.49,
			comments: ["Low grades but can perform better", "Read hard in subjects with basic grades", "Have extra revision for better grades"],
		},
		{
			min: 0.9,
			max: 1.19,
			comments: ["Low grades but can perform better", "Have extra revision for better grades", "Improve in your weak areas"],
		},
		{
			min: 0,
			max: 0.89,
			comments: ["Have extra revision for better grades"],
		},
	];
	if (alevel == true) {
		comments = [
			{
				min: 15,
				max: 20,
				comments: ["Very good grades but keep reading and do not relax.", "Good performance and do not relax", "Well done but do not relax", "Very good grades and please keep it up", "Well done please and keep it up"],
			},
			{
				min: 10,
				max: 14,
				comments: ["Good performance but do not relax", "Well done but do not relax", "Fair grades but please aim higher", "Good grades but do not relax"],
			},
			{
				min: 7,
				max: 9,
				comments: ["Good grades but please, aim higher", "Fair performance but can do better", "Good grades but please, aim higher"],
			},
			{
				min: 0,
				max: 6,
				comments: ["Low grades but can perform better", "Read hard in subjects with basic grades", "Have extra revision for better grades"],
			},
		];
	}
	for (let x = 0; x < comments.length; x++) {
		if (identifier >= comments[x].min && identifier <= comments[x].max) {
			// console.log("Identifier ", identifier);

			const excludedComment = classTeachersComment(identifier);

			// Filter out the excluded comment
			const filteredComments = comments[x].comments.filter((comment) => comment !== excludedComment);

			// Get a random comment from the remaining ones
			if (filteredComments.length > 0) {
				const randomComment = filteredComments[Math.floor(Math.random() * filteredComments.length)];
				// console.log(randomComment);
				return randomComment;
			} else {
				console.log("No comments available after exclusion.");
				return "";
			}

			// const randomComment = comments[x].comments[Math.floor(Math.random() * comments[x].comments.length)];
			// return randomComment;
			// break;
		}
	}
}

export function getGradeFromGrading(score, grading) {
	let grade = null;
	if (grading) {
		for (let x = 0; x < grading.length; x++) {
			const grd = grading[x];
			if (score >= grd.min && score <= grd.max) grade = grd;
		}
	}
	return grade;
}

export function removeEmptyRecordsALevel() {
	setTimeout(() => {
		let tables = document.querySelectorAll("table.report-table");
		tables = [...tables, ...document.querySelectorAll("table.report-table-black")];

		// console.log("tables", tables);

		if (tables.length > 0) {
			tables.forEach((tb) => {
				// if (sc.colspan)
				const rows = tb.querySelectorAll("tr");
				rows.forEach((row, rowIndex) => {
					const td1 = row.querySelector(".sb-1");
					if (td1) {
						let scores_found = 0;
						let grades = [];
						const rowspan = parseInt(td1.getAttribute("rowspan"));
						// console.log("Score in cell", rowspan);
						if (rowspan > 1) {
							for (let x = 0; x < rowspan; x++) {
								// const r2 = document.querySelectorAll();
								const r2 = rows[rowIndex + x];
								const sc = r2.querySelectorAll(".sb-sc");
								for (let i = 0; i < sc.length; i++) {
									if (sc[i].textContent.trim() != "") scores_found += 1;
								}

								// getting all grades
							}
							if (scores_found > 0) {
								for (let x = 0; x < rowspan; x++) {
									// const r2 = document.querySelectorAll();
									const r2 = rows[rowIndex + x];
									const sc = r2.querySelectorAll(".sb-sc");
									for (let i = 0; i < sc.length; i++) {
										const initials = r2.querySelector(".initials");

										if (initials.innerHTML.trim() == "") initials.innerHTML = "-";
									}
								}
							}
							// console.log("scores_found ", scores_found);
						}
					}
				});

				const initials = tb.querySelectorAll("tr .initials");
				initials.forEach((i) => {
					if (i.textContent.trim() == "") {
						// console.log(i);
						i.parentNode.style.display = "none";
					}
				});
				setTimeout(() => {
					const rows = tb.querySelectorAll("tr");
					let total_points = 0;
					let principle_passes = [];
					let subs = [];
					rows.forEach((row, rowIndex) => {
						const td1 = row.querySelector(".sb-1");
						if (td1 && row.style.display != "none") {
							let scores_found = 0;
							let grades = [];
							let $absmk = 0;
							const rowspan = parseInt(td1.getAttribute("rowspan"));
							// console.log("Score in cell", rowspan);
							if (rowspan > 0) {
								for (let x = 0; x < rowspan; x++) {
									// const r2 = document.querySelectorAll();
									const r2 = rows[rowIndex + x];
									const sc = r2.querySelectorAll(".sb-sc");
									const ave = r2.querySelector(".ave");
									// console.log("ave=", ave.textContent);

									for (let i = 0; i < sc.length; i++) {
										if (sc[i].textContent.trim() != "") scores_found += 1;
										if (ave.textContent.trim() == "") $absmk = 1;
									}
									// getting all grades
									const grd_th = r2.querySelector("th.grade");
									// console.log("grd", grd_th);
									if (r2.style.display != "none") {
										const gr = parseInt(grd_th.dataset.grv);
										// console.log("not visible", r2, gr);
										grades.push(gr);
									}
								}

								// console.log("scores_found ", scores_found);
							}
							// console.log("All grades ", grades);
							const sub = td1.parentNode.querySelector(".actualGrade").dataset.sub;
							const agrade = getALevelGrade(grades, sub, $absmk);
							// principle_passes += agrade[1];

							// console.log("principles ", agrade);

							let xVal = 0;
							if (agrade[0] == "A") xVal += 6;
							if (agrade[0] == "B") xVal += 5;
							if (agrade[0] == "C") xVal += 4;
							if (agrade[0] == "D") xVal += 3;
							if (agrade[0] == "E") xVal += 2;
							if (agrade[0] == "O") xVal += 1;

							if (sub == "true" && agrade[0] == "O") subs.push(agrade[0]);

							total_points += xVal;
							// console.log(["A", "B", "C", "D", "E"], agrade);

							if (["A", "B", "C", "D", "E"].includes(agrade[0])) principle_passes.push(agrade[0]);
							// console.log("Actual Grade ", agrade[0], "principles ", agrade[1], td1);
							td1.parentNode.querySelector(".actualGrade").innerHTML = agrade[0];
						}
					});
					// console.log("Subs = ", subs);

					console.log("Parent ", tb.parentNode.parentNode.parentNode);

					tb.parentNode.parentNode.parentNode.querySelector(".class_teachers_comment").innerHTML = classTeachersComment(principle_passes, true);
					tb.parentNode.parentNode.parentNode.querySelector(".head_teachers_comment").innerHTML = headTeachersComment(principle_passes, true);

					tb.querySelector(".total_points").innerHTML = total_points;
					tb.querySelector(".principle_passes").innerHTML = principle_passes.length;
					tb.querySelector(".subsidiary_passes").innerHTML = subs.length;
				}, 500);
			});
		}
	}, 200);
}

export function highlightColumn(event) {
	console.log(event);

	if (event.target.nodeName != "INPUT") return;
	console.log("node name ", event.target.nodeName);
	let cell = event.target.parentNode;
	console.log({ cell }, cell.cellIndex, cell.parentNode.rowIndex);
	const tbody = cell.parentNode.parentNode;
	console.log(tbody.children);
	const rows = tbody.querySelectorAll("tr");

	rows.forEach((row) => {
		const color = row.children[cell.cellIndex].style.backgroundColor;
		// if (color) row.children[cell.cellIndex].removeAttribute("style");
		// else row.children[cell.cellIndex].style.backgroundColor = "#ffd5b7";
		row.children[cell.cellIndex].style.transition = "0s";
		row.children[cell.cellIndex].style.backgroundColor = "#ffd5b7";
	});
}

export function removeColumnHighlight(event) {
	if (event.target.nodeName != "INPUT") return;
	console.log("node name ", event.target.nodeName);
	let cell = event.target.parentNode;
	console.log({ cell }, cell.cellIndex, cell.parentNode.rowIndex);
	const tbody = cell.parentNode.parentNode;
	console.log(tbody.children);
	const rows = tbody.querySelectorAll("tr");

	rows.forEach((row) => {
		row.children[cell.cellIndex].removeAttribute("style");
	});
}

function instances(numbers, needle) {
	return numbers.filter((num) => num === needle).length;
}

function count(arr) {
	return arr.length;
}

export function getALevelGrade(grades, isSub = false, $absmk = 0) {
	let $worst = 0;
	let $actualGrade = "F";
	let $mygrades = grades;
	let $passes = 0;
	let $credits = 0;
	let $ds = 0;
	let $nines = 0;
	let $msum = 0;
	let $nnm = grades.length;
	let $princi = 0;
	let $principles = 0;
	let $as = 0;
	// let $absmk = 0;

	grades.forEach((gr) => {
		// console.log("cur lp grd", gr);

		if (gr > $worst) $worst = gr;
		if ([7, 8].includes(gr)) $passes += 1;
		if ([3, 4, 5, 6].includes(gr)) $credits += 1;
		if ([1, 2].includes(gr)) $ds += 1;
		if (gr == 9) $nines += 1;
		$msum += gr;
	});

	// console.log("grade num ", grades.length);
	console.log("worst ", $worst, "passes", $passes, "credits", $credits, "ds", $ds, "nines", $nines);

	if ($nnm == 3) {
		$as = 3;
		if (instances($mygrades, 9) == count($mygrades)) $actualGrade = "F";
		else if ($worst <= 3 && $msum <= 7 && instances($mygrades, 3) < 2) $actualGrade = "A";
		else if ($worst <= 4) $actualGrade = "B";
		else if ($worst <= 5 && $msum <= 13) $actualGrade = "C";
		else if ($worst <= 5) $actualGrade = "C";
		else if ($worst <= 6 && $msum <= 16) $actualGrade = "D";
		else if (
			(instances($mygrades, 8) == 1 && instances($mygrades, 7) == 0 && instances($mygrades, 6) <= 1 && $worst <= 8 && ($credits >= 0 || $ds >= 0) && $worst == 8) ||
			(instances($mygrades, 7) == 1 && instances($mygrades, 8) == 0 && ($credits >= 0 || $ds >= 0) && $worst == 7)
		)
			$actualGrade = "E";
		else if (
			($passes >= 2 && $worst <= 8 && $nines <= 2) ||
			instances($mygrades, 7) == 3 ||
			instances($mygrades, 8) == 3 ||
			(instances($mygrades, 9) == 1 && ($passes >= 0 || $ds >= 0 || $credits >= 0)) ||
			(instances($mygrades, 9) == 1 && ($passes >= 1 || $credits >= 0 || $ds >= 0)) ||
			($nines <= 2 && instances($mygrades, 8) == 0) ||
			$nines <= 2 ||
			($passes <= 2 && $passes > 0)
		)
			$actualGrade = "O";
		// else if ($nines == 1 && $passes == 0) $actualGrade = 'O';
		else $actualGrade = "F";
		// $aak = array("worst.".$worst, "passes-".$passes, "nines-".$nines, "ds-".$ds);
		// $aaaxk = ' - three';
		// echo 'h ';
	} else if ($nnm == 4) {
		$as = 4;
		if ($worst <= 3 && $ds >= 3) $actualGrade = "A";
		else if ($worst <= 4) $actualGrade = "B";
		else if ($worst <= 5) $actualGrade = "C";
		else if ($worst <= 6) $actualGrade = "D";
		else if (
			([7, 8].includes($worst) && $passes <= 3) ||
			($worst == 8 && ($credits >= 0 || $passes >= 0 || $ds >= 0)) ||
			(instances($mygrades, 7) == 1 && ($credits >= 0 || $passes >= 0 || $ds >= 0)) ||
			(instances($mygrades, 8) == 1 && instances($mygrades, 6) <= 2 && $worst <= 8)
		)
			$actualGrade = "E";
		else if (($nines >= 1 && $worst == 9) || instances($mygrades, 7) == 4 || instances($mygrades, 8) == 4 || $worst == 9 || $nines == 2) $actualGrade = "O";
		else $actualGrade = "F";

		// echo 'it\'s 4 - ('.implode(',',$scores).') ';
		// $aaaxk = ' - four';
	} else if ($nnm == 2) {
		console.log("2 subs", grades);

		if ($ds >= 2) $actualGrade = "A";
		else if ($worst <= 3) $actualGrade = "B";
		// else if($credits == 1 && $ds == 1) $actualGrade = 'B';
		else if ($worst <= 4) $actualGrade = "C";
		else if ($worst <= 5) $actualGrade = "D";
		else if ($worst <= 6 || instances($mygrades, 6) == 2 || ($passes >= 1 && $msum <= 12)) $actualGrade = "E";
		// else if ($passes == 1 && $nines <= 0) $actualGrade = 'E';
		else if (($passes >= 1 && $msum <= 16) || ($nines == 1 && ($credits == 1 || $ds == 1))) $actualGrade = "O";
		else if (($nines == 1 && $passes == 1) || $nines > 1) $actualGrade = "F";
		else {
			$actualGrade = "F";
			// echo 'failed';
		}
	} else {
		$as = "2n1";
		if ($ds >= 2) $actualGrade = "A";
		else if ($worst <= 3) $actualGrade = "B";
		// else if($credits == 1 && $ds == 1) $actualGrade = 'B';
		else if ($worst <= 4) $actualGrade = "C";
		else if ($worst <= 6) $actualGrade = "D";
		else if ($passes == 1 && $nines <= 0) $actualGrade = "E";
		else if ($passes == 2) $actualGrade = "O";
		else if ($worst == 8 || ($worst == 7 && $msum <= 13)) $actualGrade = "O";
		else if (($nines == 1 && $ds == 1) || $credits == 1) $actualGrade = "O";
		else if ($nines > 1 && $credits == 0 && $ds == 0) $actualGrade = "F";
		else {
			$actualGrade = "F";
			// echo 'failed';
		}
		// echo $ds.','.$credits.','.$nines.','.$passes;
		// $aaaxk = ' - could be two';
		// echo 'not sure - ('.implode(',',$scores).') ';
		// echo implode(',',$mygrades);
	}
	//check if one of the scores was missing
	if ($absmk == 1) {
		$actualGrade = "X";
	}
	if (["A", "B", "C", "D", "E"].includes($actualGrade)) {
		// $principles = 1;
	}
	if (isSub.toString() == "true") {
		if ($nnm == 2) {
			if ($credits == 2) $actualGrade = "O";
			if ($ds >= 1) $actualGrade = "O";
			if ($worst < 7) $actualGrade = "O";
			else $actualGrade = "F";

			if ($absmk == 1) {
				$actualGrade = "-";
			}
		} else if ($nnm == 1) {
			if ($credits == 1 || $ds == 1) $actualGrade = "O";
			if ($worst < 7) $actualGrade = "O";
			else $actualGrade = "F";
			if ($absmk == 1) {
				$actualGrade = "-";
			}
		}
	}
	// $princi = 1;

	return [$actualGrade, $principles];
}
