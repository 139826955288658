import { EyeIcon, EyeOffIcon, PencilIcon } from "@heroicons/react/outline";
import React from "react";

function StudentList({ students, pageCount, studentLimit, setSelectedStudent, myAccount, setPopup, deactivateStudent }) {
	return (
		<>
			{students &&
				students.map((obj, index) => {
					if (index >= (pageCount - 1) * studentLimit && index <= (pageCount - 1) * studentLimit - 1 + studentLimit)
						return (
							<tr key={index} data-obj={JSON.stringify(obj)}>
								<td>{obj.id}</td>
								<td>{obj.firstName}</td>
								<td>{obj.otherName}</td>
								<td>{obj.fullName}</td>
								<td>{obj?.class?.alias}</td>
								<td>{obj?.stream?.stream}</td>
								<td>{obj.gender}</td>
								{myAccount.type == "admin" && (
									<>
										<td className="relative">
											<PencilIcon className="text-[#1e754f] bg-[#c0f7df] rounded-full w-5 p-1 border-[1px] border-[#53c292]" />
											<div
												className="inset-0 absolute"
												onClick={(event) => {
													let retrievedUser = event.target.parentNode.parentNode.dataset.obj;
													setSelectedStudent(retrievedUser);
													setPopup(true);
												}}
											></div>
										</td>
										<td className="relative">
											{obj.active == 0 ? <EyeOffIcon className="text-black rounded-full w-5 p-[2px]" /> : <EyeIcon className="text-black rounded-full w-5 p-[2px]" />}
											{/* <EyeIcon className="text-black rounded-full w-5 p-[2px]" /> */}
											<div
												className="inset-0 absolute"
												onClick={(event) => {
													let obj = JSON.parse(event.target.parentNode.parentNode.dataset.obj);
													deactivateStudent(obj.id, obj.classId);
												}}
											></div>
										</td>
									</>
								)}
							</tr>
						);
				})}
		</>
	);
}

export default StudentList;
