import { BrowserRouter, Route, Routes, useNavigate, Navigate } from "react-router-dom";
import Login from "./Modules/Login";
import Signup from "./Modules/Signup";
import Home from "./Modules/Home";
import { loader } from "./components/vanilla/Dialogues";
import { useContext, useEffect } from "react";
import axios from "axios";
import { GlobalContext } from "./GlobalContext";
import PrimaryOneAssessment from "./Modules/Students/Reports/ReportTemplates/Primary/PrimaryOneAssessment";
import PrimaryDoubleAssessments from "./Modules/Students/Reports/ReportTemplates/Primary/PrimaryDoubleAssessments";
import PrimaryLinearContinuousAssessments from "./Modules/Students/Reports/ReportTemplates/Primary/PrimaryLinearContinuousAssessments";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import NewCurriculum from "./Modules/Students/Reports/ReportTemplates/Secondary/NewCurriculum";
import AdminHome from "./Modules/Admin/AdminHome";
import LoaderComponent from "./components/LoaderComponent";
import ALevelReportCards from "./Modules/Students/Reports/ReportTemplates/Secondary/ALevelReportCards";

export default function App() {
	// const active_subpage = "Home";
	const { schoolData, token, reportMode, reportSheetData, chosenTemplate, setChosenTemplate, classes } = useContext(GlobalContext);
	useEffect(() => {
		console.log("school data =", schoolData);
	}, []);
	if ((!schoolData && token == 0) || !classes) {
		return (
			<div className="bg-white min-h-screen overflow-hidden relative flex jic">
				<p className="text-2xl font-semibold text-gray-400">Initializing ...</p>
			</div>
		);
	} else if (schoolData || token == null) {
		return (
			<>
				<div className={`bg-white ${reportMode ? "min-h-screen" : "h-screen overflow-hidden"} relative`}>
					{/* Change class when reports are getting printed. */}
					<LoaderComponent />
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Login />} />
							<Route path="/privacy-policy" element={<PrivacyPolicy />} />
							<Route path="/signup" element={<Signup />} />
							<Route path="/home/*" element={<Home />} />
							<Route path="/admin/*" element={<AdminHome />} />
						</Routes>
					</BrowserRouter>
				</div>

				{chosenTemplate && reportSheetData?.students?.length > 0 && chosenTemplate == "new_curriculum" && (
					<>
						<div className="bg-[#fff] fixed top-0 left-0 w-full h-full overflow-y-auto"></div>
						<div className="top-0 absolute left-0 flex flex-col items-center mx-auto w-full">
							<NewCurriculum data={reportSheetData} />
						</div>
					</>
				)}
				{chosenTemplate && reportSheetData?.students?.length > 0 && chosenTemplate == "a_level_report" && (
					<>
						<div className="bg-[#fff] fixed top-0 left-0 w-full h-full overflow-y-auto"></div>
						<div className="top-0 absolute left-0 flex flex-col items-center mx-auto w-full">
							<ALevelReportCards data={reportSheetData} />
						</div>
					</>
				)}

				{chosenTemplate && reportSheetData?.students?.length > 0 && chosenTemplate == "tmp1" && (
					<>
						<div className="bg-[#fff] fixed top-0 left-0 w-full h-full overflow-y-auto"></div>
						<div className="top-0 absolute left-0 flex flex-col items-center mx-auto w-full">
							<PrimaryOneAssessment data={reportSheetData} />
						</div>
					</>
				)}

				{chosenTemplate && reportSheetData?.students?.length > 0 && chosenTemplate == "tmp2" && (
					<>
						<div className="bg-[#fff] fixed top-0 left-0 w-full h-full overflow-y-auto"></div>
						<div className="top-0 absolute left-0 flex flex-col items-center mx-auto w-full">
							<PrimaryDoubleAssessments data={reportSheetData} />
						</div>
					</>
				)}

				{chosenTemplate && reportSheetData?.students?.length > 0 && chosenTemplate == "continuous-linear" && (
					<>
						<div className="bg-[#fff] fixed top-0 left-0 w-full h-full overflow-y-auto"></div>
						<div className="top-0 absolute left-0 flex flex-col items-center mx-auto w-full">
							<PrimaryLinearContinuousAssessments data={reportSheetData} />
						</div>
					</>
				)}

				{chosenTemplate && reportSheetData?.students?.length > 0 && chosenTemplate == "continous-verticle" && (
					<>
						<div className="bg-[#fff] fixed top-0 left-0 w-full h-full overflow-y-auto"></div>
						<div className="top-0 absolute left-0 flex flex-col items-center mx-auto w-full">
							<PrimaryDoubleAssessments data={reportSheetData} />
						</div>
					</>
				)}
			</>
		);
	} else {
		const token = localStorage.getItem("token");
		console.log("Token = ", token);

		if (!token) window.location = "/";
		else {
			return (
				<>
					<BrowserRouter>
						<Routes>
							<Route path="/admin/*" element={<AdminHome />} />
						</Routes>
					</BrowserRouter>
					<LoaderComponent />
				</>
			);
		}
	}
}
