import { CameraIcon, XIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";

function EditSchool() {
	return (
		<div className="fixed top-0 left-0 bg-[#0009] w-full h-full flex items-center justify-center">
			<div className="bg-[#111] flex max-w-[600px] w-full max-h-[90%] min-h-[400px] rounded-[5px] overflow-hidden" style={{ boxShadow: "#3a3a3a 0px 0px 20px 0px" }}>
				<form className="border-r border-[#333] w-[60%] flex flex-col px-3 pb-3 relative">
					<Link to={"/admin/schools"} className="bg-[#ff000017] absolute right-6 top-4 w-[25px] h-[25px] p-1 rounded-full">
						<XIcon className="text-[#f00]" />
					</Link>
					<div className="rounded-full w-[70px] h-[70px] bg-[#ccc] mx-auto mt-10 mb-2 overflow-hidden relative cursor-pointer">
						<img src="/logo512.png" className="h-full w-full object-cover" alt="" />
						<div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
							<CameraIcon className="w-[30px] text-[#000]" />
						</div>
						<input type="file" className="w-full h-full object-cover absolute left-0 top-0 opacity-0 cursor-pointer" />
					</div>
					<p className="font-bold">Student Name</p>
					<input type="text" className="mt-1 mb-2 bg-[#272727] text-[#bdbdbd] px-2 py-1 rounded-[5px]" />
					<div className="flex items-center">
						<div className="pr-1 flex flex-col">
							<p className="font-bold">Motto</p>
							<input type="text" className="mt-1 mb-2 bg-[#272727] text-[#bdbdbd] px-2 py-1 rounded-[5px]" />
						</div>
						<div className="pl-1 flex flex-col flex-grow">
							<p className="font-bold">Status</p>
							<select className="bg-[#555] py-1 px-2 rounded-[5px] cursor-pointer w-full">
								<option>ACTIVE</option>
								<option>INACTIVE</option>
							</select>
						</div>
					</div>

					<div className="flex items-center">
						<div className="pr-1 flex flex-col">
							<p className="font-bold">Email</p>
							<input type="text" className="mt-1 mb-2 bg-[#272727] text-[#bdbdbd] px-2 py-1 rounded-[5px]" />
						</div>
						<div className="pl-1 flex flex-col">
							<p className="font-bold">Phone</p>
							<input type="text" className="mt-1 mb-2 bg-[#272727] text-[#bdbdbd] px-2 py-1 rounded-[5px]" />
						</div>
					</div>
					<div className="flex items-center">
						<div className="pr-1 flex flex-col">
							<p className="font-bold">Postal Address</p>
							<input type="text" className="mt-1 mb-2 bg-[#272727] text-[#bdbdbd] px-2 py-1 rounded-[5px]" />
						</div>
						<div className="pl-1 flex flex-col">
							<p className="font-bold">Physical Address</p>
							<input type="text" className="mt-1 mb-2 bg-[#272727] text-[#bdbdbd] px-2 py-1 rounded-[5px]" />
						</div>
					</div>
				</form>
				<div className="flex-grow px-3 pb-3">
					<div className="rounded-full w-[30px] h-[30px] bg-[#ccc] mx-auto mt-10 mb-2">
						<img src="/logo512.png" className="h-full w-full object-cover" alt="" />
					</div>
					<p className="text-center mb-4">St. Charles Lwanga High School - Lubya</p>
					<table className="school-table-list">
						<tr>
							<th>Email</th>
							<td>coderscave1@gmail.com</td>
						</tr>
						<tr>
							<th>Phone</th>
							<td>+256703396923, +256784137458</td>
						</tr>
						<tr>
							<th>Motto</th>
							<td>Teri kudda mabega</td>
						</tr>
						<tr>
							<th>Physical Address</th>
							<td>P.O Box 27237 Kampala</td>
						</tr>
						<tr>
							<th>Postal Address</th>
							<td>P.O Box 27237 Kampala</td>
						</tr>
						<tr>
							<th>Status</th>
							<td>Active</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	);
}

export default EditSchool;
