import React, { useContext, useState } from "react";
import ReportWatermark from "../ReportWatermark";
import Header2 from "../../ReportHeaders/Header2";
import MainFooter from "../../ReportFooters/MainFooter";
import { GlobalContext } from "../../../../../GlobalContext";
import GradintTable from "../../ReportFooters/GradingTable";
import SecondaryHeader from "../../ReportHeaders/SecondaryHeader";
import { getGradeFromGrading, removeEmptyRecords, removeEmptyRecordsALevel } from "../../../../../functions/helpers";
import { getDescriptor, getIdentifier } from "../../../../../components/vanilla/Helpers";
import GradingTable from "../../ReportFooters/GradingTable";

function ALevelReportCards({ data }) {
	removeEmptyRecordsALevel();
	const students = data?.students;
	const term = data?.term;
	const subjects = data?.subjects;
	const initials = data?.initials;
	const grading = data?.grading;
	const assessments = data?.assessments;
	const cls = data?.class;
	let totalGrades = 0;
	let totalScores = 0;
	let allGrades = [];

	// console.log("This is my grading ", grading);
	const { schoolData, api_route } = useContext(GlobalContext);
	// const [allGrades, setAllGrades] = useState([]);
	// const [totalGrades, setTotalGrades] = useState(0);

	const getScore = (subjectId) => {
		// return new Promise((resolve) => {
		// 	resolve("Finale");
		// 	return;
		// });
		return "x";
	};

	return students.map((student) => {
		let identifiers = [];
		let ave_identifier = 0;
		let averages = [];
		let total_averages = 0;

		let wholeSubjectScoreArray = [];
		let wholeSubjectScoreCount = 0;

		let descriptor = "";
		let grade = null;

		let all_grades = [];
		return (
			<div className="max-w-[800px] w-full mx-auto" key={student.studentId}>
				<div className="h-[1072px] m-[30px] border-[2px] border-transparent bg-white relative rounded-[5px] overflow-hidden flex flex-col">
					<ReportWatermark about={schoolData} api_route={api_route} />
					<div className="relative z-10">
						<div className="border-b-[1px] border-transparent rounded-[10px] bg-[#f5f5f5]">
							<SecondaryHeader about={schoolData} api_route={api_route} student={student} />
						</div>
						<div className="border-b-[1px] border-[#dddddd] border-dashed p-[15px]">
							<div className="flex items-center">
								<p className="px-2 flex-grow">
									<span className="font-bold">NAME:</span> {student.fullName}
								</p>
								<p className="px-2 w-[100px]">
									<span className="font-bold">CLASS:</span> {cls.alias}
								</p>
								<p className="px-2 w-[70px]">
									<span className="font-bold">TERM:</span> {term.term}
								</p>
								<p className="px-2 w-[100px]">
									<span className="font-bold">YEAR:</span> {term.year}
								</p>
								<p className="px-2 w-[100px] hidden">
									<span className="font-bold">STREAM:</span> BRIGHT
								</p>
							</div>
						</div>
						<p className="py-2 text-center font-bold text-[12px] text-[#fff] bg-[#1a2e3a] my-3">
							<u>
								TERM {term.term}, {term.year}
							</u>{" "}
							ADVANCED LEVEL ACADEMIC PERFORMANCE REPORT
						</p>
					</div>
					<div className="z-10">
						<div className="mx-0 overflow-hidden">
							<table cellSpacing={0} className="pad6 w-full text-[11px] font-semibold report-table-black">
								<thead>
									<tr className="text-left thead" style={{ background: "#555", color: "#fff" }}>
										<th>SUBJECTS</th>
										<th className="bg-[#5cbcf91a] w-10">PAPER</th>
										{assessments.map((assessment, index) => (
											<th className="text-center p-3" key={`assessment-${index}`}>
												{assessment.title}
											</th>
										))}
										<th className="text-center p-3 bg-[#5cbcf91a]">AVE</th>
										<th className="text-center w-[60px] p-3 bg-[#5cbcf91a]">AGG</th>
										<th className="text-center w-[60px] p-3 bg-[#5cbcf91a]">GRADE</th>
										<th className="text-center p-3 bg-[#5cbcf91a]">REMARKS</th>
										<th className="w-[50px] p-3 bg-[#5cbcf91a]">INITIALS</th>
									</tr>
								</thead>
								<tbody>
									{subjects.map((subject) => {
										let subject_teacher = null;
										if (subject.teacher_subjects && subject.teacher_subjects?.length > 0) subject_teacher = subject.teacher_subjects[0].teacher;
										if (subject?.subject_units?.length > 0) {
											let allSubjectScores = [];
											let total_scores = 0;
											let isValidSubject = 0;
											return subject.subject_units.map((unit, u_index) => {
												let sc = "";
												return (
													<tr key={student.studentId + "-" + subject.id + "-" + unit.id} className="text-[#000] text-[10px]">
														{u_index == 0 && (
															<th align="left" rowSpan={subject.subject_units.length > 0 ? subject.subject_units.length : 1} className="sb-1">
																{subject.subject.length > 13 ? subject.alias : subject.subject}
															</th>
														)}
														<th className="bg-[#5cbcf91a]">{unit.unit}</th>
														{student.assessments.map((assessment, index) => {
															let scores = assessment.scores;
															sc = "";
															grade = null;
															// total_scores = 0;
															// wholeSubjectScoreArray = [];
															// allSubjectScores = [];
															for (let x = 0; x < scores.length; x++) {
																if (scores[x].subjectUnitId == unit.id) {
																	sc = scores[x].score;
																	// if (sc == null) sc = 0;
																	total_scores += sc == null ? 0 : sc;
																	allSubjectScores = [...allSubjectScores, sc == null ? 0 : sc];
																	wholeSubjectScoreArray.push(sc == null ? 0 : sc);
																	if (allSubjectScores.length >= assessments.length) break;
																}
															}
															grade = getGradeFromGrading(total_scores > 0 ? (total_scores / assessments.length).toFixed(0) : 0, grading);
															return (
																<td className="text-center p-3 sb-sc" key={`assessment-score-${index}`}>
																	{sc}
																</td>
															);
														})}
														<th className="text-center p-3 ave">{total_scores > 0 ? (total_scores / assessments.length).toFixed(0) : ""}</th>
														<th className="text-center w-[60px] p-3 text-[#f00] grade" data-grv={grade.value} data-sub={subject.sub}>
															{total_scores > 0 ? grade.symbol : ""}
														</th>
														{u_index == 0 && (
															<th className="text-center w-[100px] p-3 actualGrade" data-sub={subject.sub} rowSpan={subject.subject_units.length > 0 ? subject.subject_units.length : 1}></th>
														)}
														<th className="text-left p-3">{total_scores > 0 ? grade.remark : ""}</th>
														<th className="w-[50px] p-3 text-[#f00] initials">
															{wholeSubjectScoreArray.length > 0
																? subject_teacher
																	? `${subject_teacher?.fname?.substring(0, 1)?.toUpperCase()}${subject_teacher?.lname?.substring(0, 1)?.toUpperCase()}`
																	: "-"
																: ""}
															{student.assessments.map(() => {
																wholeSubjectScoreArray = [];
																total_scores = 0;
																return;
															})}
														</th>
													</tr>
												);
											});
										} else {
											let oscore = null;
											let sc = null;
											let m_total_scores = 0;
											let m_all_scores = [];
											{
												student.assessments.map((assessment, index) => {
													m_total_scores = 0;
													m_all_scores = [];
													grade = null;
													for (let x = 0; x < assessment.scores.length; x++) {
														if ((assessment.scores[x].subjectId = subject.id)) {
															// console.log("subject name ", subject.alias);
															sc = assessment.scores[x].score;

															m_total_scores += sc;
															m_all_scores.push(sc);

															// console.log();

															if (m_all_scores.length >= assessments.length) break;
														}
													}
													// console.log("All scores ", m_all_scores);
													grade = getGradeFromGrading(m_total_scores > 0 ? (m_total_scores / assessments.length).toFixed(0) : 0, grading);
												});
											}
											return (
												<tr key={student.studentId + "-" + subject.id} className="text-[#000] text-[10px]">
													<th align="left" rowSpan={subject.subject_units.length > 0 ? subject.subject_units.length : 1} className="sb-1">
														{subject.subject.length > 13 ? subject.alias : subject.subject}
													</th>
													<th align="left"></th>
													{student.assessments.map((assessment, index) => {
														// m_total_scores = 0;
														// m_all_scores = [];
														// grade = null;
														for (let x = 0; x < assessment.scores.length; x++) {
															sc = null;
															if ((assessment.scores[x].subjectId = subject.id && assessment.scores[x].subjectUnitId == null)) {
																console.log("scores ++ ", assessments.scores);
																console.log("subject name ", subject.alias);
																console.log(assessment);

																sc = assessment.scores[x].score;
																break;
															}
														}
														// console.log("All scores ", m_all_scores);
														// grade = getGradeFromGrading(m_total_scores > 0 ? (m_total_scores / assessments.length).toFixed(0) : 0, grading);

														return (
															<td className="text-center p-3 sb-sc" key={`assessment-score-${index}`}>
																{sc}
															</td>
														);
													})}
													<th className="text-center p-3 ave">{(m_total_scores / assessments.length).toFixed(0)}</th>
													<th className="text-center w-[60px] p-3 text-[#f00] grade" data-grv={grade.value}>
														{m_total_scores > 0 ? grade.symbol : ""}
													</th>
													<th className="text-center w-[60px] p-3 actualGrade" data-sub={subject.sub}>
														GRADE
													</th>
													<th className="text-left w-[60px] p-3">{m_total_scores > 0 ? grade.remark : ""}</th>
													<th className="w-[50px] p-3 text-[#f00] initials">
														{sc != null ? (subject_teacher ? `${subject_teacher?.fname?.substring(0, 1)?.toUpperCase()}${subject_teacher?.lname?.substring(0, 1)?.toUpperCase()}` : "-") : ""}
													</th>
												</tr>
											);
										}
									})}
									<tr style={{ backgroundColor: "transparent" }}>
										<th colSpan={9}></th>
									</tr>
									<tr>
										<th style={{ backgroundColor: "#ffd696", color: "#000" }} colSpan={2}>
											TOTAL POINTS
										</th>
										<th style={{ backgroundColor: "#ffd696", color: "#000" }} className="total_points">
											0
										</th>
										<th style={{ backgroundColor: "#addbbb", color: "#000" }} colSpan={2}>
											PRINCIPLE PASSES
										</th>
										<th style={{ backgroundColor: "#addbbb", color: "#000" }} className="principle_passes">
											0
										</th>
										<th style={{ backgroundColor: "#ffcac8", color: "#000" }} colSpan={2}>
											SUBSIDIARY PASSES
										</th>
										<th style={{ backgroundColor: "#ffcac8", color: "#000" }} className="subsidiary_passes">
											0
										</th>
									</tr>
								</tbody>
							</table>
						</div>
						<p className="pt-5 text-[14px] font-bold"></p>

						<GradingTable grading={grading} />

						<table cellSpacing={0} className="pad6 w-full text-[11px] black font-semibold mt-3">
							<tbody>
								<tr>
									<th align="left">This Term Ends:</th>
									<th align="left" className="w-[120px]">
										6th December 2024
									</th>
									<th align="left">Next Term Begins:</th>
									<th align="left" className="w-[120px]">
										3rd February 2025
									</th>
									<th align="left">Fees Balance:</th>
									<th align="left" className="w-[120px]"></th>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="z-10 border-t-[1px] border-[#ddd] bg-[#fbfbfb] flex flex-col">
						<MainFooter cls={data.class} student={student} />
						<p className="text-center text-[#999] pb-2 pt-1 italic">This report card should carry a stamp.</p>
					</div>
				</div>
			</div>
		);
	});
}

export default ALevelReportCards;
