import { ClockIcon, CurrencyDollarIcon, FolderIcon, HomeIcon, LogoutIcon, UserCircleIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import Schools from "./Schools";

import "../../styles/admin.css";
import EditSchool from "./EditSchool";
import Mail from "./Mail";

function AdminHome() {
	const navigate = useNavigate();

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (!token) window.location = "/";
	}, []);

	return (
		<div className="bg-[#282828] min-h-full text-[#ccc]">
			<div className="h-screen w-full justify-center items-center hidden">
				<p>Loading ...</p>
			</div>
			<main className={`h-screen overflow-hidden flex`}>
				{/* class will change when printing reports */}
				<div className="w-[250px] border-r border-[#000] flex flex-col flex-shrink-0">
					<div className="flex items-center border-b border-[#000] px-4 py-6">
						<img src="/icon-100.png" className="w-[35px]" />
						<div className="flex flex-col leading-0 text-sm">
							<p className="font-bold">Schoolware</p>
							<p className="text-[10px] text-gray-500" style={{ marginTop: "-10px" }}>
								Your school software
							</p>
						</div>
					</div>
					<div className="items-center border-b border-[#000] p-[6px] bg-[#0009] text-white font-semibold hidden">MUKIIBI PETER</div>
					<div className="flex flex-col flex-grow overflow-x-hidden overflow-y-auto">
						<div className="p-4 flex items-center hover:bg-[#323232]">
							<HomeIcon className="text-[#ffcaa6] w-5 mr-2" />
							<Link to="/admin/schools" className="block flex-grow">
								Schools Records
							</Link>
						</div>
						<div className="p-4 flex items-center hover:bg-[#323232]">
							<CurrencyDollarIcon className="text-[#ffcaa6] w-5 mr-2" />
							<Link to="/home/sm/students" className="block flex-grow">
								Payments
							</Link>
						</div>
						<div className="p-4 flex items-center hover:bg-[#323232]">
							<UserCircleIcon className="text-[#ffcaa6] w-5 mr-2" />
							<Link to="/home/sm/students" className="block flex-grow">
								Users
							</Link>
						</div>
						<div className="p-4 flex items-center hover:bg-[#323232]">
							<img src="/gmail.png" className="w-6 mr-2" alt="" />
							<Link to="/admin/mail" className="block flex-grow">
								Mail
							</Link>
						</div>

						<div className="p-4 flex items-center hover:bg-[#323232]">
							<LogoutIcon className="text-red-500 w-5 mr-2" />
							<Link
								to="/"
								className="text-xs flex-grow"
								onClick={(event) => {
									event.preventDefault();
									localStorage.removeItem("token");
									// window.location.href = "/";
									navigate("/", { replace: true });
								}}
							>
								Logout
							</Link>
						</div>
					</div>
				</div>
				<div className="flex flex-col flex-grow overflow-hidden">
					<Routes>
						<Route path="/*" element={<Schools />} />
						<Route path="schools" element={<Schools />} />
						<Route path="mail" element={<Mail />} />
					</Routes>
				</div>
			</main>
		</div>
	);
}

export default AdminHome;
