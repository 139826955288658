import React from "react";

function SecondaryHeader({ about, student, api_route }) {
	// console.log("About =", about);
	return (
		<div className="flex items-center z-30 relative">
			<div className="w-[150px] m-[20px] mr-[10px] flex justify-center items-center overflow-hidden">
				<img src={`${api_route}/images/${about.logo ?? "icon-100.png"}`} className="object-fill" />
			</div>
			<div className="flex flex-col flex-grow text-center">
				<p className="hidden">السلام معك ومع عائلتك</p>
				<p className="text-[19px] font-bold">
					{about.name} {about.subtitle}
				</p>
				<p className="text-[16px]">{about.motto}</p>
				<p className="text-[16px] font-bold">{about.postal_address}</p>
				<p className="">
					TEL: {about.phonecontact}, Email:
					{about.email}
				</p>
			</div>
			{!student?.picture ? (
				<div className="rounded-[7px] bg-[#eee] border-2 border-[#ccc] w-[100px] h-[100px] m-[20px] ml-[20px] flex flex-col justify-center font-bold text-[#757575] items-center overflow-hidden text-[10px]">
					<p>NO PHOTO</p>
					<p>AVAILABLE</p>
				</div>
			) : (
				<div className="rounded-[7px] w-[100px] border-2 border-[#ccc] h-[100px] m-[20px] ml-[20px] flex justify-center items-center overflow-hidden">
					<img src={`${api_route}/images/${student?.picture ? student.picture : "icon-100.png"}`} className="object-fill" />
				</div>
			)}
		</div>
	);
}

export default SecondaryHeader;
