import React from "react";

function LoaderComponent() {
	return (
		<div
			id="loader"
			style={{
				display: "none",
			}}
		>
			<div className="flex justify-center items-center">
				<div className="flex flex-col items-center justify-center">
					<img src="/spinner.png" />
					<p className="font-bold mt-4">Loading ...</p>
				</div>
			</div>
		</div>
	);
}

export default LoaderComponent;
