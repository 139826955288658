import { CameraIcon, EyeIcon, EyeOffIcon, PencilIcon } from "@heroicons/react/outline";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../../GlobalContext";

function StudentRow({ obj, index, api_route, myAccount, deactivateStudent, setSelectedStudent, setPopup, inputs, setInputs }) {
	const initialState = {
		id: obj.id,
		firstName: obj.firstName,
		otherName: obj.otherName,
		fullName: obj.fullName,
		dob: "2023-01-01",
		email: obj.email,
		_class: obj?.class?.id,
		stream: obj?.stream?.id,
		gender: obj.gender,
		image: null,
	};
	const [studentRow, setStudentRow] = useState(initialState);
	return (
		<tr key={index} data-obj={JSON.stringify(obj)}>
			<td>
				<div className="relative rounded-full w-[50px] h-[50px] mx-auto flex justify-center items-center overflow-hidden">
					<img src={`${api_route}/images/${obj?.picture ? obj.picture : "icon-100.png"}`} className={`object-fill ${obj?.picture == null ? "opacity-20" : ""}`} />
					<CameraIcon className="w-[30px] text-center text-white mx-auto bg-black rounded-full absolute opacity-[0.09]" />
					<input
						type="file"
						name="image"
						className="absolute w-full h-full inset-0 cursor-pointer opacity-0"
						onChange={(event) => {
							event.target.parentNode.children[0].src = window.URL.createObjectURL(event.target.files[0]);
							event.target.parentNode.children[0].style.opacity = 1;
							setInputs((prev) => ({
								...prev,
								image: event.target.files[0],
							}));
						}}
						accept="image/*"
					/>
				</div>
			</td>
			<td className="bg-[#24f4c01a]">{obj.id}</td>
			<td className="relative">
				<input type="text" className="absolute top-0 left-0 h-full w-full p-[5px] bg-transparent" value={obj.firstName} required />
			</td>
			<td className="relative">
				<input type="text" className="absolute top-0 left-0 h-full w-full p-[5px] bg-transparent" value={obj.otherName} required />
			</td>
			<td className="relative">
				<input type="text" className="absolute top-0 left-0 h-full w-full p-[5px] bg-transparent" value={obj.fullName} required />
			</td>
			<td>{obj?.class?.alias}</td>
			<td>{obj?.stream?.stream}</td>
			<td>{obj.gender}</td>
			{myAccount.type == "admin" && (
				<>
					<td className="relative">
						<PencilIcon className="text-[#1e754f] bg-[#c0f7df] rounded-full w-5 p-1 border-[1px] border-[#53c292]" />
						<div
							className="inset-0 absolute"
							onClick={(event) => {
								let retrievedUser = event.target.parentNode.parentNode.dataset.obj;
								setSelectedStudent(retrievedUser);
								setPopup(true);
							}}
						></div>
					</td>
					<td className="relative">
						{obj.active == 0 ? <EyeOffIcon className="text-black rounded-full w-5 p-[2px]" /> : <EyeIcon className="text-black rounded-full w-5 p-[2px]" />}
						{/* <EyeIcon className="text-black rounded-full w-5 p-[2px]" /> */}
						<div
							className="inset-0 absolute"
							onClick={(event) => {
								let obj = JSON.parse(event.target.parentNode.parentNode.dataset.obj);
								deactivateStudent(obj.id, obj.classId);
							}}
						></div>
					</td>
				</>
			)}
		</tr>
	);
}

function AdminStudentList({ students, pageCount, studentLimit, setSelectedStudent, myAccount, setPopup, deactivateStudent, inputs, setInputs }) {
	const { api_route } = useContext(GlobalContext);

	return (
		<>
			{students &&
				students.map((obj, index) => {
					if (index >= (pageCount - 1) * studentLimit && index <= (pageCount - 1) * studentLimit - 1 + studentLimit)
						return (
							<StudentRow
								inputs={inputs}
								setInputs={setInputs}
								setSelectedStudent={setSelectedStudent}
								setPopup={setPopup}
								deactivateStudent={deactivateStudent}
								myAccount={myAccount}
								index={index}
								obj={obj}
								api_route={api_route}
							/>
						);
				})}
		</>
	);
}

export default AdminStudentList;
