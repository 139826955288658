import React, { useContext, useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { customPrompt, failedAlert, loader, successAlert, warningAlert } from "../../../components/vanilla/Dialogues";
// import {
// 	customPrompt,
// } from "../../../components";

import PrimaryCummulative from "../Reports/Primary/PrimaryCummulative";
import PrimaryTwoSets from "../Reports/Primary/PrimaryTwoSets";
import "../css/report-tables.css";
import PrimaryOneSet from "../Reports/Primary/PrimaryOneSet";
import NewCurriculum from "../Reports/Secondary/NewCurriculum";
import OldVersion from "../Reports/Secondary/OldVersion";
import Hsc from "../Reports/Secondary/Hsc";
import "../css/marksheets.css";
// import { selectClasses } from "../../redux/slice";
// import { useSelector } from "react-redux";
import { CheckIcon, ExclamationCircleIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import { GlobalContext } from "../../../GlobalContext";
import ChooseTemplate from "./ChooseTemplate";
import PrimaryOneAssessment from "./ReportTemplates/Primary/PrimaryOneAssessment";
import ChooseSecondaryTemplate from "./ChooseSecondaryTemplate";

function ReportRequest({ setOpenTemplates, openTemplates }) {
	const [studentName, setStudentName] = useState("");
	const [data, setData] = useState({
		selectedClass: 0,
		streamId: 0,
		year: 0,
		assessments: [],
		assessmentId: [],
		termId: 0,
		pageCount: 1,
	});
	const [assessments, setAssessments] = useState([]);
	const [students, setStudents] = useState([]);
	const [terms, setTerms] = useState([]);
	const { myAxios, classes, schoolData, myAccount, setReportMode, api_route, setReportSheetData, chosenTemplate, setChosenTemplate, reportSheetData } = useContext(GlobalContext);
	const [template, setTemplate] = useState(null);
	// const [selectedClass, setSelectedClass] = useState(0);
	// const [steram, setStream] = useState(0);
	const [years, setYears] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [setup, setSetup] = useState(true);
	const [total_students, setTotalStudents] = useState(0);
	const [limit, setLimit] = useState(10000);
	const [openReportPreview, setOpenReportPreview] = useState(false);
	const [reportHtml, setReportHtml] = useState(null);
	const [countReports, setCountReports] = useState(null);

	// useEffect(() => {
	//     console.log(data);
	// }, [data]);

	const findScore = (scores, assessmentId, studentId, subjectId, subjectUnitId = 0) => {
		let myscore = "";
		scores.map((score) => {
			if (subjectUnitId > 0) {
				if (score.subjectId == subjectId && score.studentId == studentId && score.assessmentId == assessmentId && score.subjectUnitId == subjectUnitId) {
					myscore = score.score == null ? "-" : score.score;
				}
			} else if (score.subjectId == subjectId && score.studentId == studentId && score.assessmentId == assessmentId) {
				{
					myscore = score.score == null ? "-" : score.score;
				}
			}
		});
		return myscore;
	};

	const getStudents = () => {
		loader(1);
		myAxios
			.post("students/find", {
				classId: data.selectedClass,
				returnType: "pk",
			})
			.then((results) => {
				loader(0);
				// console.log("All students = ", results.data);
			})
			.catch((error) => {
				loader(0);
				console.log(error);
			});
	};
	const attemptedSubjects = (scores, studentId) => {
		let subjects = [];
		// console.log(scores, studentId);
		// return "--k";
		scores.map((s) => {
			if (s.studentId == studentId && !subjects.includes(s.subjectId)) subjects = [...subjects, s.subjectId];
		});
		// console.log("Subjects", subjects);
		return subjects;
	};

	useEffect(() => {
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.get("/sheet/getAllYears")
			.then((response) => {
				let data = response.data;
				// console.log("Years ", data);
				// setSheetData(data);
				// setData(prev => ({...prev, ye}))
				setYears(data);
				// loader();
			})
			.catch((error) => {
				failedAlert("A problem occured.");
				// console.log("error");
				loader();
			});

		// const url3 = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.get("/allTerms")
			.then((response) => {
				let data = response.data;
				loader();
				// console.log("Terms -- ", data);
				setTerms(data);
			})
			.catch((error) => {
				loader();
				failedAlert(`Request faile. Please try again. ${error}`);
			});

		// Assessments
		// loader(1);
	}, []);
	const getAssessments = (termId) => {
		// if (data.termId == 0) return;
		// const url2 = `${process.env.REACT_APP_API_URL}`;
		loader(1);
		myAxios
			.post("/findByPK", { termId })
			.then((response) => {
				let data = response.data;
				loader();
				// console.log("Assessments -- ", data);
				setAssessments(data);
				setData((prev) => ({ ...prev, assessments: data }));
				loader(0);
			})
			.catch((error) => {
				loader();
				failedAlert(`Request failed. Please try again. ${error}`);
			});
	};
	const initialSheetData = {
		assessments: [],
		subjects: [],
		students: [],
		term: {},
		class: {},
		grading: null,
	};
	const [sheetData, setSheetData] = useState(initialSheetData);
	const closePrintPreview = (event) => {
		// console.log(event.target);
		// if(event.target.className.match(/printPreview/gi))
	};
	const getSheet = (report = false, event) => {
		setIsLoaded(false);

		// { selectedClass: '2', streamId: '5', year: '2023' }
		const classId = data.selectedClass;
		const streamId = data.streamId;
		const year = 2023;
		const assessmentId = data.assessmentId;
		// console.log("Data", props.data);
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		let requestBody = {
			...data,
			api_route,
			classId,
			streamId,
			assessmentId,
			rowLimit: limit,
			templateId: chosenTemplate?.template,
			report: 1,
			studentName,
			reportOutput: openReportPreview,
			countReports,
		};

		if (report) {
			const element = event.target;
			requestBody = {
				...requestBody,
				reportOutput: true,
			};
			if (element.dataset.lf) {
				requestBody = {
					...requestBody,
					lf: parseInt(element.dataset.lf),
					rt: parseInt(element.dataset.rt),
				};
			} else if (element.dataset.id) {
				requestBody = {
					...requestBody,
					studentId: parseInt(element.dataset.id),
				};
			}
			console.log("element ", element);
			setOpenReportPreview(true);
		} else setSheetData(initialSheetData);
		console.log(requestBody);

		myAxios
			.post("/sheet/getSheet", requestBody)
			.then((response) => {
				let data = response.data;
				console.log("Report data = ", data);
				window.setTimeout(() => {
					setIsLoaded(true);
					setSetup(false);
				}, 100);
				// setData(data);
				if (data?.students) setStudents(data.students);
				loader();
				if (requestBody.studentId || requestBody.lf) {
					setReportSheetData(data);
					// document.getElementById("report_preview").innerHTML = data;
					// setTimeout(() => {
					// 	document.getElementById("report_preview").style.display = "block";
					// }, 200);
					// showImages(api_route);
					// console.log("Data again ", data);
					// alert();
				} else {
					// console.log(sheetData);

					setTotalStudents(data.students.length);
				}
			})
			.catch((error) => {
				console.log(error);
				// failedAlert("A problem occured.");
				console.log("error");
				loader();
			});
	};
	const showImages = (api_route) => {
		const reports = document.querySelectorAll("#report_preview > .report-main");
		reports.forEach((rp) => {
			const imgs = rp.querySelectorAll("img");
			imgs.forEach((img) => {
				img.src = `${api_route}${img.getAttribute("src")}`;
			});
		});
	};
	useEffect(() => {
		console.log("Here are the assessments", data.assessmentId);
		// return;
		if (data.assessmentId.length > 0) getSheet();
	}, [data]);

	// useEffect(() => {
	// 	if (schoolData.type == "primary") {
	// 		let count = data.assessmentId.length;
	// 		if (count == 1) setTemplate("pr-one-set");
	// 		if (count == 2) setTemplate("pr-two-sets");
	// 		if (count > 2) setTemplate("pr-cummulative");
	// 	} else setTemplate("sec-cummulative");
	// 	// console.log("Selected assessments = ", data.assessmentId);
	// }, [data]);

	// const printRange = () => {
	// 	loader(1);
	// 	// const url = `${process.env.REACT_APP_API_URL}`;
	// 	myAxios
	// 		.post("/sheet/getSheet", {
	// 			...data,
	// 			classId,
	// 			streamId,
	// 			assessmentId,
	// 			rowLimit: limit,
	// 			report: 1,
	// 			studentName,
	// 		})
	// 		.then((response) => {})
	// 		.catch((error) => {});
	// };

	return (
		<div className={`flex-grow overflow-x-hidden overflow-y-auto ${!openTemplates ? "flex" : "hidden"}`}>
			<div className="flex flex-col items-center justify-center w-full h-full">
				<div className="flex max-w-[900px] w-full">
					<div className="w-[300px] flex-shrink-0 px-2">
						<div className="flex items-center flex-wrap">
							<p className="w-full py-2 text-gray-500 text-md font-bold">Select class</p>
							{classes.length > 0 &&
								classes.map((obj, key) => (
									<label className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative w-[60px]`} key={"class-" + key}>
										<input
											type="radio"
											name="classId"
											value={obj.id}
											{...(data.selectedClass == obj.id && {
												checked: true,
											})}
											onChange={(event) => {
												let val = parseInt(event.target.value);
												setData((prev) => ({
													...prev,
													selectedClass: val,
												}));
											}}
										/>
										<p className="mr-auto">{obj.alias}</p>
										<span className="flex-shrink-0"></span>
										<div className="absolute inset-0"></div>
									</label>
								))}
						</div>
						<div className="flex items-center flex-wrap">
							{classes.map((cls, key) => cls.id == data.selectedClass && cls.streams.length > 0 && <p className="w-full py-2 text-gray-500 text-md font-bold">Select stream</p>)}
							{classes.map((cls, key) =>
								cls.id == data.selectedClass ? (
									cls.streams.map((str, id) => (
										<label className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative mr-2`} key={"stream-" + id}>
											<input
												type="radio"
												name="streamId"
												value={str.id}
												{...(data.streamId == str.id && {
													checked: true,
												})}
												onChange={(event) => {
													let val = parseInt(event.target.value);
													setData((prev) => ({
														...prev,
														streamId: val,
													}));
												}}
											/>
											<p className="mr-auto flex-shrink-0">{str.stream}</p>
											<span className="flex-shrink-0"></span>
											<div className="absolute inset-0"></div>
										</label>
									))
								) : (
									<></>
								)
							)}
						</div>
						<div className="flex items-center flex-wrap">
							{data.selectedClass > 0 && <p className="w-full py-2 text-gray-500 text-md font-bold">Year</p>}
							{data.selectedClass > 0 &&
								years.map((y, index) => (
									<label className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative mr-2`} key={"year-" + index}>
										<input
											type="radio"
											name="year"
											value={y.year}
											{...(data.year == y.year && {
												checked: true,
											})}
											onChange={(event) => {
												let val = parseInt(event.target.value);
												setData((prev) => ({
													...prev,
													year: val,
													assessmentId: [],
												}));
											}}
										/>
										<p className="mr-auto flex-shrink-0">{y.year}</p>
										<span className="flex-shrink-0"></span>
										<div className="absolute inset-0"></div>
									</label>
								))}
						</div>
						<div className="flex items-center flex-wrap">
							{data.selectedClass > 0 && data.year > 0 && <p className="w-full py-2 text-gray-500 text-md font-bold">Select Term</p>}
							{terms.map((term, index) => {
								return term.year == data.year ? (
									<label className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative mr-2`} key={"term-" + index}>
										<input
											type="radio"
											name="termId"
											value={term.id}
											{...(data.termId == term.id && {
												checked: true,
											})}
											onChange={(event) => {
												let val = parseInt(event.target.value);
												setData((prev) => ({
													...prev,
													termId: val,
													assessmentId: [],
												}));
												getAssessments(val);
											}}
										/>
										<p className="mr-auto flex-shrink-0">
											{parseInt(term.term) == 1 ? "TERM ONE" : ""}
											{parseInt(term.term) == 2 ? "TERM TWO" : ""}
											{parseInt(term.term) == 3 ? "TERM THREE" : ""}
											<span className="text-red-500">{" (" + data.year + ")"}</span>
										</p>
										<span className="flex-shrink-0"></span>
										<div className="absolute inset-0"></div>
									</label>
								) : (
									<></>
								);
							})}
						</div>
						<div className="flex items-center flex-wrap">
							{data.selectedClass > 0 && data.termId > 0 && <p className="w-full py-2 text-gray-500 text-md font-bold">Select Assessment</p>}
							{assessments.map((obj, index) => {
								return obj.termId === data.termId ? (
									<label className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative mr-2 max-w-[100px]`} key={"assessment-" + index}>
										<input
											type="checkbox"
											name="assessmentId"
											value={obj.id}
											{...(data.assessmentId.includes(obj.id) && {
												checked: true,
											})}
											onChange={(event) => {
												setTotalStudents(0);
												let val = parseInt(event.target.value);
												setSheetData(initialSheetData);
												const selected = event.target.checked;
												let x = data.assessmentId;
												if (!selected) {
													// remove this from the assessments
													let a = x.filter((element) => element != val);
													console.log("Selected assessments", a);
													setData((prev) => ({
														...prev,
														assessmentId: a,
													}));
												} else {
													// remove this from the assessments
													let xx = data.assessmentId;
													xx.push(val);
													console.log("New assessments", xx);
													setData((prev) => ({
														...prev,
														assessmentId: xx,
													}));
												}
											}}
										/>
										<p className="mr-auto flex-shrink-0">{obj.title}</p>
										<span className="flex-shrink-0">
											<CheckIcon className="w-full text-white" />
										</span>
										<div className="absolute inset-0"></div>
									</label>
								) : (
									<></>
								);
							})}
						</div>
					</div>
					<div className={`flex-shrink-0 w-[250px] px-2`}>
						{data.year > 0 && data.selectedClass > 0 && data.assessmentId.length > 0 && schoolData.type == "primary" && (
							<ChooseTemplate
								setOpenTemplates={setOpenTemplates}
								chosenTemplate={chosenTemplate}
								data={data}
								studentName={studentName}
								setStudentName={setStudentName}
								setLimit={setLimit}
								getSheet={getSheet}
								api_route={api_route}
								setCountReports={setCountReports}
								setChosenTemplate={setChosenTemplate}
							/>
						)}

						{data.year > 0 && data.selectedClass > 0 && data.assessmentId.length > 0 && schoolData.type == "secondary" && (
							<ChooseSecondaryTemplate
								setOpenTemplates={setOpenTemplates}
								chosenTemplate={chosenTemplate}
								data={data}
								studentName={studentName}
								setStudentName={setStudentName}
								setLimit={setLimit}
								getSheet={getSheet}
								api_route={api_route}
								setCountReports={setCountReports}
								setChosenTemplate={setChosenTemplate}
							/>
						)}
					</div>
					{total_students < 0 && (
						<div className="text-center flex-grow text-lg h-full flex flex-col justify-center items-center text-[#999] bg-[#f4f4f4] rounded-[15px] mx-2">
							<ExclamationCircleIcon className="text-[#f00] w-[20px]" />
							No records found<br></br>Check your marksheet
						</div>
					)}

					{total_students > 0 && (
						<div className="flex-grow h-full flex flex-col text-[#444] mx-2 px-2">
							<p className="border-b-[1px] border-b-[#ccc] mb-4 pb-2 text-[#000] text-sm font-bold">
								(<span className="text-red-500">{total_students}</span>) Students found {data?.students?.length}
							</p>
							{total_students < 11 && (
								<>
									{/* <p>{total_students}</p>
									{students?.map((student) => (
										<p>{student.fullName}</p>
									))} */}
									<div className="rounded-md font-bold flex items-center bg-[#2e2e2e] text-white px-3 py-2 text-xs relative">
										Print All reports
										<div
											onClick={(event) => {
												if (!chosenTemplate) failedAlert("Please choose a template first");
												else getSheet(true, event);
											}}
											data-lf={1}
											data-rt={total_students}
											className="absolute top-0 left-0 w-full h-full"
										></div>
									</div>
									<p className="text-xs text-[#999] mt-4">Choose a name to preview a report card.</p>
								</>
							)}
							{total_students > 10 && (
								<>
									<div className="flex items-center flex-wrap gap-2">
										{[...Array(Math.ceil(total_students / 10)).keys()]
											.map((i) => i + 1)
											.map((x) => (
												<div className="rounded-full flex items-center bg-[#eee] px-3 py-1 text-xs relative">
													{x > 1 ? x * 10 - 10 + 1 : x}-{x * 10}
													<div
														onClick={(event) => {
															if (!chosenTemplate) failedAlert("Please choose a template first");
															else getSheet(true, event);
														}}
														data-lf={x > 1 ? x * 10 - 10 + 1 : x}
														data-rt={x * 10}
														className="absolute top-0 left-0 w-full h-full"
													></div>
												</div>
											))}
									</div>
									<p className="text-xs text-[#999] mt-4">Click on a button to print report cards in that range.</p>
								</>
							)}
						</div>
					)}
				</div>
			</div>

			{/* <div className="bg-[#fff] fixed top-0 left-0 w-full h-full overflow-y-auto">
				<PrimaryOneAssessment data={data} />
			</div> */}
			<Routes>
				<Route path="pr-one-set/:page/:perpage" element={<PrimaryOneSet sheetData={sheetData} data={data} findScore={findScore} attemptedSubjects={attemptedSubjects} schoolData={schoolData} />} />
				<Route path="pr-two-sets/:page/:perpage" element={<PrimaryTwoSets sheetData={sheetData} data={data} findScore={findScore} attemptedSubjects={attemptedSubjects} schoolData={schoolData} />} />
				<Route path="pr-cummulative" element={<PrimaryCummulative sheetData={sheetData} data={data} findScore={findScore} attemptedSubjects={attemptedSubjects} schoolData={schoolData} />} />
				<Route path="sec-cummulative/:page/:perpage" element={<NewCurriculum sheetData={sheetData} data={data} findScore={findScore} attemptedSubjects={attemptedSubjects} schoolData={schoolData} />} />
				<Route path="sec-report-oldversion" element={<OldVersion sheetData={sheetData} data={data} findScore={findScore} attemptedSubjects={attemptedSubjects} schoolData={schoolData} />} />
				<Route path="hsc-uneb" element={<Hsc sheetData={sheetData} data={data} findScore={findScore} attemptedSubjects={attemptedSubjects} schoolData={schoolData} />} />
			</Routes>
		</div>
	);
}

export default ReportRequest;
