import { ArrowRightIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { GlobalContext } from "../../../GlobalContext";

function ChooseSecondaryTemplate({ data, studentName, setStudentName, setLimit, getSheet, setOpenTemplates, api_route, setCountReports }) {
	const { chosenTemplate, setChosenTemplate, setReportSheetData } = useContext(GlobalContext);
	useEffect(() => {
		const closeReportCardPreview = () => {
			setReportSheetData([]);
		};
		window.addEventListener("keyup", function (event) {
			if (event.key == "Escape") closeReportCardPreview();
		});
	}, []);
	return (
		<>
			<p className="font-bold text-lg mb-3 text-gray-400">Choose Template below</p>

			<label className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative my-2`}>
				<input
					type="checkbox"
					name="assessmentId"
					value={"new_curriculum"}
					{...(chosenTemplate == "new_curriculum" && {
						checked: true,
					})}
					{...(chosenTemplate != "new_curriculum" && {
						checked: false,
					})}
					onChange={(event) => {
						console.log("Checked ", event.target.checked);
						if (event.target.checked) setChosenTemplate("new_curriculum");
						else setChosenTemplate("");
					}}
				/>
				<p className="mr-auto flex-shrink-0">New Curriculum</p>
				<span className="flex-shrink-0">
					<CheckIcon className="w-full text-white" />
				</span>
				<div className="absolute inset-0"></div>
			</label>
			<label className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative my-2`}>
				<input
					type="checkbox"
					name="assessmentId"
					value={"a_level_report"}
					{...(chosenTemplate == "a_level_report" && {
						checked: true,
					})}
					{...(chosenTemplate != "a_level_report" && {
						checked: false,
					})}
					onChange={(event) => {
						console.log("Checked ", event.target.checked);
						if (event.target.checked) setChosenTemplate("a_level_report");
						else setChosenTemplate("");
					}}
				/>
				<p className="mr-auto flex-shrink-0">A-Level Report Card</p>
				<span className="flex-shrink-0">
					<CheckIcon className="w-full text-white" />
				</span>
				<div className="absolute inset-0"></div>
			</label>

			<div className="flex items-center my-2 bg-[#e3e3e3] rounded-full overflow-hidden">
				<input type="text" maxLength={20} placeholder="Student name" className="bg-transparent flex-grow px-4 py-3 font-xs" value={studentName} onChange={(event) => setStudentName(event.target.value)} />
				<ArrowRightIcon
					onClick={() => {
						setLimit(10000);
						getSheet();
					}}
					className="text-[#444] w-8 p-2 h-8 bg-[#ccc] rounded-full mr-2 cursor-pointer"
				/>
			</div>
		</>
	);
}

export default ChooseSecondaryTemplate;
