import { PlusCircleIcon } from "@heroicons/react/outline";
import React from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import EditSchool from "./EditSchool";

function Schools() {
	return (
		<div className="w-full max-w-[1200px] mx-auto px-3 py-6">
			<div className="text-xl font-bold mb-4">
				<span className="text-[#ffcaa6] mr-2">1</span>
				<span>Schools listed below</span>
			</div>
			<div className="bg-[#444] rounded-[10px] p-2">
				<div className="flex items-center mb-2">
					<Link to={"edit"} className="flex items-center bg-[#466153] py-[3px] px-1 rounded-[5px]">
						<PlusCircleIcon className="w-[25px] mr-2" />
						<div className="pr-2">New Record</div>
					</Link>
					<form className="ml-auto flex items-center">
						<div className="overflow-hidden rounded-[5px] bg-[#555] border-[#656565] border-[1px] mr-2">
							<input type="text" placeholder="Find school ..." className="bg-transparent py-1 px-2" />
							<button className="px-2 bg-[#333] border-l-[1px] border-[#656565] py-1">search</button>
						</div>
						<select className="bg-[#555] py-1 px-2 rounded-[5px] border-[#656565] border-[1px] cursor-pointer">
							<option>REGISTRAR</option>
							<option>MUKIIBI PETER</option>
						</select>
					</form>
				</div>
				<table className="school-table-list">
					<thead>
						<tr>
							<th className="w-[30px]"></th>
							<th>School name</th>
							<th>Type</th>
							<th>Phone</th>
							<th>Email</th>
							<th>Status</th>
							<th>Registrar</th>
							<th className="w-[50px]"></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div className="w-[30px] h-[30px] mx-auto bg-[#ccc] overflow-hidden rounded-full">
									<img src="/logo512.png" alt="logo" className="object-cover w-full h-full" />
								</div>
							</td>
							<td>St. Charles Lwanga High School</td>
							<td>SECONDARY</td>
							<td>0703396923</td>
							<td>ntarestephen@gmail.com</td>
							<td>ACTIVE</td>
							<td>MUKIIBI PETER</td>
							<td className="relative">
								<Link to={"edit/3"} className="absolute left-0 top-0 w-full h-full bg-[#0033184f] flex justify-center items-center">
									EDIT
								</Link>
							</td>
						</tr>
					</tbody>
				</table>
				<Routes>
					<Route path="schools/edit" element={<EditSchool />} />
					<Route path="schools/edit/:id" element={<EditSchool />} />
				</Routes>
			</div>
		</div>
	);
}

export default Schools;
