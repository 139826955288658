import { ArrowRightIcon } from "@heroicons/react/outline";
import React, { useContext } from "react";
import { failedAlert, loader } from "../../components/vanilla/Dialogues";
import { GlobalContext } from "../../GlobalContext";

function Mail() {
	// loader(1);
	const { myAxios } = useContext(GlobalContext);
	const getAuthUrl = () => {
		loader(1);
		myAxios
			.post("/gmail-auth")
			.then((response) => {
				console.log(response.data.authUrl);
				loader(0);
				window.location = response.data.authUrl;
			})
			.catch((error) => {
				loader(0);
				console.log(error);
				failedAlert("Could not get Auth URL");
			});
	};
	return (
		<div className="flex justify-center items-center h-full">
			<div className="">
				<div className="flex items-center justify-center">
					<img src="/gmail.png" className="w-[70px] mr-2" alt="" />
					<p className="text-4xl font-bold">GMAIL</p>
				</div>
				<button className="flex items-center mx-auto mt-5 bg-black px-7 py-3 rounded-full" onClick={getAuthUrl}>
					<span className="flex-shrink-0 mr-3 font-bold">signin here</span>
					<ArrowRightIcon className="w-5" />
				</button>
			</div>
		</div>
	);
}

export default Mail;
