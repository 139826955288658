import React, { useContext } from "react";
import GradingScale from "../GradingScale";
import MainFooter from "../ReportFooters/MainFooter";
import MainHeader from "../ReportHeaders/MainHeader";
import PrintPreview from "../PrintPreview";
import { GlobalContext } from "../../../../GlobalContext";
import { useParams } from "react-router-dom";

function PrimaryOneSet(props) {
	const { sheetData } = props;
	const { api_route } = useContext(GlobalContext);
	const { page, perpage } = useParams();
	console.log("sheetData");
	console.log(sheetData);
	const cls = sheetData.class;
	const subjects = sheetData.subjects;
	const term = sheetData.term;
	const students = sheetData.students;
	const assessments = sheetData.assessments;
	const grading = sheetData.grading;
	const initials = sheetData.initials;

	const RowComponent = (props) => {
		const { subject, score, grd } = props;
		console.log(subject, score);
		let teacher_initials = "";

		initials.map((tr) => {
			if (tr.subjectId == subject.id) teacher_initials = tr.initials;
		});

		if (!score) return <></>;
		else {
			let showGrade = score.score == null ? false : true;
			return (
				<tr className="text-xs font-semibold">
					<td align="left">{subject.subject.length > 10 ? subject.alias : subject.subject}</td>
					<td className="text-center">{score.score != null ? score.score : "-"}</td>
					<td className="text-center">{score && (showGrade ? score.rank_class : "-")}</td>
					<th className="text-center text-green-900">{showGrade ? grd?.symbol : "-"}</th>
					<td className="text-xs text-left">{showGrade ? grd?.remark : "-"}</td>
					<td className="text-xs text-[#f00] text-center">{teacher_initials}</td>
				</tr>
			);
		}
	};

	return (
		<PrintPreview>
			{students.map((student, index) => {
				if (index + 1 >= page * perpage - 10 && index + 1 <= page * perpage) {
					let scores = student.assessments[0].scores;
					let total_score = 0;
					let total_grade = 0;
					return (
						<div className="mx-auto w-full bg-white report-a4-height p-6 flex flex-col select-none">
							<MainHeader term={term} photo={student.picture} schoolData={props.schoolData} />
							<div className="flex-grow flex items-center justify-center relative">
								<div className="absolute h-2/3 w-2/3 flex items-center justify-center z-50">
									<img draggable={false} src={`${api_route}/images/${!props.schoolData.logo ? "icon-100.png" : props.schoolData.logo}`} className="object-cover opacity-[0.09] min-w-[100%] min-h-[100%] max-w-[140%]" alt="school badge" />
								</div>
								<div className="absolute inset-0 h-full w-full overflow-hidden flex flex-col pb-2">
									<div className="flex-grow">
										<div className="flex items-center flex-wrap">
											<div className="w-full">
												<p className="text-lg text-center font-bold my-2 text-red-400">
													TERM {term.term == 1 ? "ONE" : term.term == 2 ? "TWO" : "THREE"} {" " + term.year + " "}
													REPORT CARD
												</p>
											</div>
											<div className="w-1/3">
												<p>
													<b>NAME: {student.fullName}</b>
												</p>
											</div>
											<div className="w-1/3">
												<p>
													<b>CLASS: </b> {cls.class} ({cls.alias})
												</p>
											</div>
											<div className="w-1/3">
												<p>
													<b>
														TERM {term.term == 1 ? "ONE" : term.term == 2 ? "TWO" : "THREE"} {" " + term.year}
													</b>
												</p>
											</div>
										</div>
										<div className="flex">
											<div className="w-full flex flex-col">
												<table cellPadding={10} className="bare-table isolated-borders mr-3 my-3">
													<thead>
														<tr className="bg-[#eee] text-[12px]">
															<th className="border-[0px] text-left">SUBJECT</th>
															<th className="w-[80px]">SCORE</th>
															<th className="w-[110px]">RANK</th>
															<th className="w-[80px]">GRADE</th>
															<th className="text-left">REMARKS</th>
															<th className="w-[50px]">INITIALS</th>
														</tr>
													</thead>
													<tbody>
														{subjects.map((subject, index) => {
															let score, grd;
															scores.map((sc) => {
																if (sc.subjectId == subject.id) score = sc;
															});
															if (score) total_score += score.score;
															if (grading && score) {
																grading?.map((g) => {
																	if (score.score >= g.min && score.score <= g.max && score.score != null) {
																		grd = g;
																		total_grade += g.value;
																	}
																});
															}
															return <RowComponent key={index} score={score} grd={grd} subject={subject} />;
														})}
														<tr>
															<th align="left">TOTAL</th>
															<th>{total_score}</th>
															<th></th>
															<th>{total_grade}</th>
														</tr>
													</tbody>
												</table>
												<div className="mr-3 text-[11px] flex items-center mb-2 font-bold text-green-800">
													<p className="flex-grow pr-3 text-left">AGG: 8</p>
													<p className="flex-grow px-3 text-left">CLASS POS: 4/25</p>
													<p className="flex-grow px-3 text-left">STREAM POS: 1/14</p>
													<p className="flex-grow px-3 text-left">DIV: I</p>
												</div>
											</div>
										</div>
										<div className="flex-wrap hidden">
											<div className="w-[25%] flex-shrink-0">
												<div className="pr-2">
													<p className="font-bold">CONDUCT</p>
													<p>Well behaved Well behaved Well behaved</p>
												</div>
											</div>
											<div className="w-[25%] flex-shrink-0">
												<div className="pr-2">
													<p className="font-bold mt-2">ATTENDANCE</p>
													<p>Well behaved Well behaved Well behaved</p>
												</div>
											</div>
											<div className="w-[25%] flex-shrink-0">
												<div className="pr-2">
													<p className="font-bold mt-2">SMARTNESS</p>
													<p>Well behaved Well behaved Well behaved</p>
												</div>
											</div>
											<div className="w-[25%] flex-shrink-0">
												<div className="pr-2">
													<p className="font-bold mt-2">PUNCTUALITY</p>
													<p>Well behaved Well behaved Well behaved</p>
												</div>
											</div>
										</div>
									</div>
									<GradingScale grading={grading} />
								</div>
							</div>
							<MainFooter student={student} cls={cls} />
						</div>
					);
				}
			})}
		</PrintPreview>
	);
}

export default PrimaryOneSet;
